import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Homepage from "./pages/Homepage";
import Profile from "./pages/Profile";
import "./App.css";

function App() {
  return (
    <div className="body">
        <Router>
          <Routes>
            <Route path="/" element={<Homepage />} />
            <Route path="/Profile" element={<Profile />} />
          </Routes>
        </Router>
      </div>
  );
}

export default App;
