import React, { useState } from "react";
import Grid from "@mui/material/Grid";
import { Link } from "react-router-dom";
import "../App.css";
import "../components/Homepage.css";

function Homepage() {
  const [isShifted, setIsShifted] = useState(false);

  const handleClick = () => {
    setIsShifted(!isShifted);
  };

  return (
    <div className="App">
      <div className="card">
        <div className="grid-container">
          <Grid xs={4}>
            <p className="cardTitle">
              Wangechi <br /> Murimi
            </p>
          </Grid>
          <Grid xs={8}>
            <p className="abstract-one">
              Hello, I am an Early-Career Professional, establishing my
              expertise in crafting tech systems for people-centric experiences.
              <br /> <br />
              My enthusiasm extends beyond just code, to architecting solutions
              that resonate with the human experience, ensuring my work shapes a
              future where technology serves us, understands us, and most
              importantly, remembers us.
              <br />
            </p>
          </Grid>
          <Grid xs={4} className="left-column">
            <div className="social-links">
              <a
                href="https://www.linkedin.com/in/wangechimurimi"
                target="_blank"
                rel="noopener noreferrer"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path d="M 5 3 C 3.895 3 3 3.895 3 5 L 3 19 C 3 20.105 3.895 21 5 21 L 19 21 C 20.105 21 21 20.105 21 19 L 21 5 C 21 3.895 20.105 3 19 3 L 5 3 z M 5 5 L 19 5 L 19 19 L 5 19 L 5 5 z M 7.7792969 6.3164062 C 6.9222969 6.3164062 6.4082031 6.8315781 6.4082031 7.5175781 C 6.4082031 8.2035781 6.9223594 8.7167969 7.6933594 8.7167969 C 8.5503594 8.7167969 9.0644531 8.2035781 9.0644531 7.5175781 C 9.0644531 6.8315781 8.5502969 6.3164062 7.7792969 6.3164062 z M 6.4765625 10 L 6.4765625 17 L 9 17 L 9 10 L 6.4765625 10 z M 11.082031 10 L 11.082031 17 L 13.605469 17 L 13.605469 13.173828 C 13.605469 12.034828 14.418109 11.871094 14.662109 11.871094 C 14.906109 11.871094 15.558594 12.115828 15.558594 13.173828 L 15.558594 17 L 18 17 L 18 13.173828 C 18 10.976828 17.023734 10 15.802734 10 C 14.581734 10 13.930469 10.406562 13.605469 10.976562 L 13.605469 10 L 11.082031 10 z"></path>
                </svg>
              </a>

              <a
                href="https://discuss.systems/@wmurimi"
                target="_blank"
                rel="noopener noreferrer"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  fill="currentColor"
                  class="bi bi-mastodon"
                  viewBox="0 0 16 16"
                >
                  <path d="M11.19 12.195c2.016-.24 3.77-1.475 3.99-2.603.348-1.778.32-4.339.32-4.339 0-3.47-2.286-4.488-2.286-4.488C12.062.238 10.083.017 8.027 0h-.05C5.92.017 3.942.238 2.79.765c0 0-2.285 1.017-2.285 4.488l-.002.662c-.004.64-.007 1.35.011 2.091.083 3.394.626 6.74 3.78 7.57 1.454.383 2.703.463 3.709.408 1.823-.1 2.847-.647 2.847-.647l-.06-1.317s-1.303.41-2.767.36c-1.45-.05-2.98-.156-3.215-1.928a4 4 0 0 1-.033-.496s1.424.346 3.228.428c1.103.05 2.137-.064 3.188-.189zm1.613-2.47H11.13v-4.08c0-.859-.364-1.295-1.091-1.295-.804 0-1.207.517-1.207 1.541v2.233H7.168V5.89c0-1.024-.403-1.541-1.207-1.541-.727 0-1.091.436-1.091 1.296v4.079H3.197V5.522q0-1.288.66-2.046c.456-.505 1.052-.764 1.793-.764.856 0 1.504.328 1.933.983L8 4.39l.417-.695c.429-.655 1.077-.983 1.934-.983.74 0 1.336.259 1.791.764q.662.757.661 2.046z" />
                </svg>
              </a>
              <a href="mailto:hello@wangechimurimi.com">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path d="M 4 4 C 2.895 4 2 4.895 2 6 L 2 18 C 2 19.105 2.895 20 4 20 L 20 20 C 21.105 20 22 19.105 22 18 L 22 6 C 22 4.895 21.105 4 20 4 L 4 4 z M 5.5976562 6 L 18.402344 6 L 12 10 L 5.5976562 6 z M 5 8.6269531 L 12 13 L 19 8.6269531 L 19 18 L 5 18 L 5 8.6269531 z"></path>
                </svg>
              </a>
            </div>
            <button className="button">
              <Link to="/Profile">
                <span className="button-content" onClick={handleClick}>
                  Profile
                </span>
              </Link>
            </button>
            <button className="button">
              <a href="https://wmurimi.substack.com/">
                <span className="button-content" onClick={handleClick}>
                  Blog
                </span>
              </a>
            </button>
            {/*
            <button className="button">
              <Link to="/Resume">
                <span className="button-content" onClick={handleClick}>
                  {" "}
                  Resume{" "}
                </span>
              </Link>
            </button>
             */}
          </Grid>
          <Grid xs={8}>
            <p className="abstract-two">
              Presently growing my learned knowledge and skills through
              contributions in engineering projects and roles. I am grounded to
              this sociotechnical approach, committed to both research and
              practical innovation as ultimate objectives.
            </p>
          </Grid>
        </div>
      </div>

      <div className="mobile-card">
        <div className="grid-container">
          <Grid container spacing={2}>
            <Grid xs={8}>
              <p className="cardTitle">
                Wangechi <br /> Murimi
              </p>
            </Grid>

            <Grid xs={4}>
              <div className="mobile-links">
                <a
                  href="https://www.linkedin.com/in/wangechimurimi"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    fill="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path d="M 5 3 C 3.895 3 3 3.895 3 5 L 3 19 C 3 20.105 3.895 21 5 21 L 19 21 C 20.105 21 21 20.105 21 19 L 21 5 C 21 3.895 20.105 3 19 3 L 5 3 z M 5 5 L 19 5 L 19 19 L 5 19 L 5 5 z M 7.7792969 6.3164062 C 6.9222969 6.3164062 6.4082031 6.8315781 6.4082031 7.5175781 C 6.4082031 8.2035781 6.9223594 8.7167969 7.6933594 8.7167969 C 8.5503594 8.7167969 9.0644531 8.2035781 9.0644531 7.5175781 C 9.0644531 6.8315781 8.5502969 6.3164062 7.7792969 6.3164062 z M 6.4765625 10 L 6.4765625 17 L 9 17 L 9 10 L 6.4765625 10 z M 11.082031 10 L 11.082031 17 L 13.605469 17 L 13.605469 13.173828 C 13.605469 12.034828 14.418109 11.871094 14.662109 11.871094 C 14.906109 11.871094 15.558594 12.115828 15.558594 13.173828 L 15.558594 17 L 18 17 L 18 13.173828 C 18 10.976828 17.023734 10 15.802734 10 C 14.581734 10 13.930469 10.406562 13.605469 10.976562 L 13.605469 10 L 11.082031 10 z"></path>
                  </svg>
                </a>

                <a
                  href="https://discuss.systems/@wmurimi"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    fill="currentColor"
                    class="bi bi-mastodon"
                    viewBox="0 0 16 16"
                  >
                    <path d="M11.19 12.195c2.016-.24 3.77-1.475 3.99-2.603.348-1.778.32-4.339.32-4.339 0-3.47-2.286-4.488-2.286-4.488C12.062.238 10.083.017 8.027 0h-.05C5.92.017 3.942.238 2.79.765c0 0-2.285 1.017-2.285 4.488l-.002.662c-.004.64-.007 1.35.011 2.091.083 3.394.626 6.74 3.78 7.57 1.454.383 2.703.463 3.709.408 1.823-.1 2.847-.647 2.847-.647l-.06-1.317s-1.303.41-2.767.36c-1.45-.05-2.98-.156-3.215-1.928a4 4 0 0 1-.033-.496s1.424.346 3.228.428c1.103.05 2.137-.064 3.188-.189zm1.613-2.47H11.13v-4.08c0-.859-.364-1.295-1.091-1.295-.804 0-1.207.517-1.207 1.541v2.233H7.168V5.89c0-1.024-.403-1.541-1.207-1.541-.727 0-1.091.436-1.091 1.296v4.079H3.197V5.522q0-1.288.66-2.046c.456-.505 1.052-.764 1.793-.764.856 0 1.504.328 1.933.983L8 4.39l.417-.695c.429-.655 1.077-.983 1.934-.983.74 0 1.336.259 1.791.764q.662.757.661 2.046z" />
                  </svg>
                </a>
                <a href="mailto:hello@wangechimurimi.com">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    fill="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path d="M 4 4 C 2.895 4 2 4.895 2 6 L 2 18 C 2 19.105 2.895 20 4 20 L 20 20 C 21.105 20 22 19.105 22 18 L 22 6 C 22 4.895 21.105 4 20 4 L 4 4 z M 5.5976562 6 L 18.402344 6 L 12 10 L 5.5976562 6 z M 5 8.6269531 L 12 13 L 19 8.6269531 L 19 18 L 5 18 L 5 8.6269531 z"></path>
                  </svg>
                </a>
              </div>
            </Grid>

            <Grid xs={12}>
              <p className="abstract-mobile">
                Hello, I am an Early-Career Professional, establishing my
                expertise in crafting tech systems for people-centric
                experiences.
                <br /> <br />
                My enthusiasm extends beyond just code, and to architecting
                solutions that resonate with the human experience, ensuring my
                work shapes a future where technology serves us, understands us,
                and most importantly, remembers us.
                <br /> <br />
                Presently growing my learned knowledge and skills through
              contributions in engineering projects and roles. I am grounded to
              this sociotechnical approach, committed to both research and
              practical innovation as ultimate objectives.
              </p>
            </Grid>

            <Grid xs={12} className="left-column">
              <button className="button">
                <Link to="/Profile">
                  <span className="button-content" onClick={handleClick}>
                    Profile
                  </span>
                </Link>
              </button>
              <button className="button">
                <a href="https://wmurimi.substack.com/">
                  <span className="button-content" onClick={handleClick}>
                    Blog
                  </span>
                </a>
              </button>
              {/*
           <button className="button">
             <Link to="/Resume">
               <span className="button-content" onClick={handleClick}>
                 {" "}
                 Resume{" "}
               </span>
             </Link>
           </button>
            */}
            </Grid>
          </Grid>
        </div>
      </div>

      <div className="footer">
        <p>© 2023 - 2024 Wangechi Murimi</p>
      </div>
    </div>
  );
}

export default Homepage;
